import { createHashHistory } from 'history';
import auth from './auth/reducer';
import general from './general/reducer';
import file from './file/reducer';
import freshService from './freshService/reducer';
import { combineReducers, configureStore } from '@reduxjs/toolkit';

export const history = createHashHistory();

export const createRootReducer = combineReducers({
    auth,
    general,
    file,
    freshService,
});

export const store = configureStore({
    reducer: createRootReducer,
    devTools: process.env.NODE_ENV === 'development',
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

export default store;
export const dispatch = store.dispatch;
export const getState = store.getState;
