import { useMutation } from '@tanstack/react-query';
import { CREATE_FRESH_SERVICE_TICKET } from '../queryConstants';
import { queryErrorHandler, queryNoReturnContentSuccessHandler } from '../queryStateHandlers';
import FreshHttpService from '../../../service/http/freshService/freshHttpService';
import { IFreshServiceTicketUpsert } from '../../../@types/model/freshService/freshServiceTicketModels';

export const useHandleCreateFreshTicket = () => {
    return useMutation<void, Error, IFreshServiceTicketUpsert>({
        mutationKey: [CREATE_FRESH_SERVICE_TICKET],
        mutationFn: (upsert : IFreshServiceTicketUpsert) => FreshHttpService.createTicket(upsert),
        onError: queryErrorHandler('Error creating Fresh Ticket.'),
        onSuccess: queryNoReturnContentSuccessHandler('Fresh Ticket created successfully.'),
    });
};
