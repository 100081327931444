import axios from 'axios';
import { IGoogleLogInModel } from '../../../@types/model/auth/logIn/GoogleLogInModel';
import { LogInModel } from '../../../@types/model/auth/logIn/LogInModel';
import { IUserToken } from '../../../@types/model/auth/userToken/userToken';
import { SignUpModel } from '../../../@types/model/auth/logIn/SignUpModel';

export default class AuthHttpService {
    public static signUp = async (googleSignUp : SignUpModel) : Promise<IUserToken> => {
        const result = await axios.post(`${API_URL}/v1/Authorisation/SignUp`, googleSignUp);
        return result.data;
    }

    public static googleLogIn = async (googleLogIn : IGoogleLogInModel) : Promise<IUserToken> => {
        const result = await axios.post(`${API_URL}/v1/Authorisation/LogInGoogle`, googleLogIn);
        return result.data;
    }

    public static logIn = async (logIn : LogInModel) : Promise<IUserToken> => {
        const result = await axios.post(`${API_URL}/v1/Authorisation/LogIn`, logIn);
        return result.data;
    }

    public static getSession = async () : Promise<IUserToken> => {
        const result = await axios.get(`${API_URL}/v1/Authorisation/GetSession`);
        return result.data;
    }

    public static logout = async () : Promise<void> => {
        await axios.get(`${API_URL}/v1/Authorisation/Logout`);
    }
}
