import { CapitalTransactionPurpose } from './@types/model/capital';
import { RequisitionLineStatus, RequisitionStatus } from './@types/model/requisition/requisition';
import { VatType } from './@types/model/masterData/masterData';

/* eslint-disable no-unused-vars */
export enum RIGHT_GRANTS {
    View = 0,
    Add = 1,
    Edit = 2,
    Delete = 3,
    Admin = 20,
}

// Always use white space between date and time.
export const DATE_FORMAT_DEFAULT_NO_SECONDS = 'DD/MM/YYYY HH:mm';
export const DATE_FORMAT_DEFAULT = 'DD/MM/YYYY HH:mm:ss';
export const DATE_FORMAT_DEFAULT_NO_TIME = 'DD/MM/YYYY';
export const DATE_FORMAT_NO_SECONDS = 'YYYYMMDD HH:mm';
export const DATE_FORMAT_SUMMARY = 'DD-MM-YYYY';


export const RIGHT_LEVELS : Record<string, number> = {
    'View': RIGHT_GRANTS.View,
    'Add': RIGHT_GRANTS.Add,
    'Edit': RIGHT_GRANTS.Edit,
    'Delete': RIGHT_GRANTS.Delete,
    'Admin': RIGHT_GRANTS.Admin,
};

export const RIGHT_GRANT_LEVELS : Array<{ label : string; value : number }> = [
    { label: 'View', value: RIGHT_GRANTS.View },
    { label: 'Add', value: RIGHT_GRANTS.Add },
    { label: 'Edit', value: RIGHT_GRANTS.Edit },
    { label: 'Delete', value: RIGHT_GRANTS.Delete },
    { label: 'Admin', value: RIGHT_GRANTS.Admin },
];

export const capitalPlanPurposes = [
    { id: CapitalTransactionPurpose.Additional, label: 'Additional' },
    { id: CapitalTransactionPurpose.Adjusted, label: 'Adjusted' },
    { id: CapitalTransactionPurpose.Budgeted, label: 'Budgeted' },
    { id: CapitalTransactionPurpose.Saved, label: 'Saved' },
    { id: CapitalTransactionPurpose.Transferred, label: 'Transferred' },
    { id: CapitalTransactionPurpose.Triggered, label: 'Triggered' },
];

export const requisitionStatuses = [
    { id: RequisitionStatus.Cancelled, label: 'Cancelled' },
    { id: RequisitionStatus.Draft, label: 'Draft' },
    { id: RequisitionStatus.Submitted, label: 'Submitted' },
    { id: RequisitionStatus.Ordered, label: 'Ordered' },
    { id: RequisitionStatus.PartiallyReceived, label: 'Partially Received' },
    { id: RequisitionStatus.Received, label: 'Received' },
    { id: RequisitionStatus.Closed, label: 'Closed' },
];

export const procurementReqLineStatuses = [
    { id: RequisitionLineStatus.Cancelled, label: 'Cancelled' },
    { id: RequisitionLineStatus.Draft, label: 'Draft' },
    { id: RequisitionLineStatus.Submitted, label: 'Submitted' },
    { id: RequisitionLineStatus.Ordered, label: 'Ordered' },
    { id: RequisitionLineStatus.PartiallyReceived, label: 'Partially Received' },
    { id: RequisitionLineStatus.Received, label: 'Received' },
    { id: RequisitionLineStatus.Closed, label: 'Closed' },
];

export const capitalPlanPurposeOptions = capitalPlanPurposes
    .filter(x => x.id !== CapitalTransactionPurpose.Budgeted)
    .map(x => ({ label: x.label, value: x.id }));

export const allDepartmentRights = [
    10, // Rights
    11, // User Management
];

export const vatOptions = [
    { label: VatType[VatType.Incl], value: VatType.Incl },
    { label: VatType[VatType.Excl], value: VatType.Excl },
    { label: VatType[VatType.Zero], value: VatType.Zero },
];

export const DEFAULT_VAT_RATE = 0.15;
// Regex used to remove standard illegal file system symbols and characters falling within emoji ranges.
// eslint-disable-next-line no-control-regex
export const VALID_FILENAME_REGEX = /[|/\\":*?<>{}\x00-\x1F]|[\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF]/g;

export const QUERY_DATA_STALE_TIME = 30 * 60 * 1000;

/*ZZFresh service */

export enum FreshServicePriority {
    Na = 0,
    Low,
    Medium,
    High,
    Urgent,
}

export enum FreshServiceSourceType {
    Na = 0,
    Email,
    Portal,
    Phone,
    Chat,
    FeedbackWidget,
    Yammer,
    AwasCloudwatch,
    PageDuty,
    Walkup,
    Slack,
}

export enum FreshServiceStatus {
    Na = 1,
    Open,
    Pending,
    Resolved,
    Closed,
}