import React from 'react';
import { IFreshServiceTicketFormValues } from '../../@types/model/freshService/freshServiceTicketModels';
import { Button } from '@mui/material';
import { FormMuiAutocompleteSelect, FormTextInput, IOptionType, PillButton } from '@zz2/zz2-ui';
import { Form, FormikBag, FormikProps, withFormik } from 'formik';
import FreshServiceTicketModelHelper from '../../@types/model/freshService/freshServiceTicketModelHelper';

interface IFreshServiceTicketFormProps {
    initialValues : IFreshServiceTicketFormValues;
    onSubmit : (values : IFreshServiceTicketFormValues) => void;
    onCancel : () => void;

    userOptions : Array<IOptionType>;
}

type FreshServiceTicketEditFormPropsType = IFreshServiceTicketFormProps & FormikProps<IFreshServiceTicketFormValues>;

const FreshServiceTicketForm = (props : FreshServiceTicketEditFormPropsType) : React.ReactElement => {

    return (
        <Form>
            <div className={'fdc p30 pt15 pb0'}>
                <div className={'fdc wfill pt10 jcc aic'}>
                    <FormTextInput
                        name={'systemName'}
                        label={'System Name'}
                        className={'wfill'}
                        disabled
                    />
                    <div className='h10' />
                    <FormTextInput
                        name={'systemVersion'}
                        label={'System Version'}
                        className={'wfill'}
                        disabled
                    />
                    <div className='h10' />
                    <FormTextInput
                        name={'location'}
                        label={'Location'}
                        className={'wfill'}
                        disabled
                    />
                    <div className='h10' />
                    <FormTextInput
                        name={'name'}
                        label={'Name'}
                        className={'wfill'}
                        disabled
                    />
                    <div className='h10' />
                    <FormTextInput
                        name={'email'}
                        label={'Email'}
                        className={'wfill'}
                        disabled
                    />
                    <div className='h10' />
                    <FormTextInput
                        name={'description'}
                        label={'Description'}
                        className={'wfill'}
                        multiline={true}
                        rows={4}
                    />
                    <div className='h10' />
                    <FormMuiAutocompleteSelect 
                        name='referencedUser'
                        label={'Manager'} 
                        className={'wfill'}
                        options={props.userOptions}
                    />
                </div>
                <div className='h20' />
                <div className={'fdr flx1 aic jcfe mb20'}>
                    <Button
                        className={'cpd mr20'}
                        onClick={props.onCancel}
                        type={'reset'}>
                        CANCEL
                    </Button>
                    <PillButton
                        className={'pl30 pr30'}
                        text={'SAVE'}
                        size={'small'}
                        type={'submit'}
                        color={'secondary'}
                        disabled={!props.dirty || !props.isValid || props.isSubmitting}
                    />
                </div>
            </div>
        </Form>
    );
};

const handleSubmit = (values : IFreshServiceTicketFormValues, formikBag : FormikBag<IFreshServiceTicketFormProps, IFreshServiceTicketFormValues>) : void => {
    formikBag.props.onSubmit(values);
};

export default withFormik<IFreshServiceTicketFormProps, IFreshServiceTicketFormValues>({
    mapPropsToValues: props => props.initialValues,
    handleSubmit,
    enableReinitialize: true,
    validationSchema: FreshServiceTicketModelHelper.formSchema,
}) (FreshServiceTicketForm);
