
import { createAction } from 'typesafe-actions';
import { IFileQueue } from '../../@types/model/fileUpload/fileUpload';

const fileActions = {
    setFiles: createAction('FILE_SET_FILES', resolve => (files : Array<IFileQueue>) => resolve(files)),
    setProgress: createAction('FILE_SET_PROGRESS', resolve => (progress : number) => resolve(progress)),
    setCurrent: createAction('FILE_SET_CURRENT', resolve => (current ?: string) => resolve(current)),
};

export default fileActions;
