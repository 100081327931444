import { ThemeProvider } from '@mui/material';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { IGoogleLoginError, IGoogleLoginSuccess, IOptionType, Loader, Login } from '@zz2/zz2-ui';
import * as React from 'react';
import { createContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import { IAppContext } from './@types/model/appContext';
import { IGoogleLogInModel } from './@types/model/auth/logIn/GoogleLogInModel';
import { LogInModel } from './@types/model/auth/logIn/LogInModel';
import { SignUpModel } from './@types/model/auth/logIn/SignUpModel';
import { IPasswordRecoveryFormValues } from './@types/model/auth/password/passwordRecoveryFormValues';
import { IUserToken } from './@types/model/auth/userToken/userToken';
import { useAppDispatch, useAppSelector } from './@types/redux';
import { QUERY_DATA_STALE_TIME } from './appConstants';
import PasswordRecoveryModelHelper from './modules/root/helper/passwordRecoveryModelHelper';
import NavFrame from './modules/root/NavFrame';
import PasswordRecovery from './modules/root/PasswordRecovery';
import SnackbarNotifier from './modules/root/SnackbarNotifier';
import EnvHelper from './service/helper/envHelper';
import { initializeInterceptor } from './service/http';
import * as localStorageService from './service/localStorageService';
import AuthActions from './store/auth/actions';
import AuthThunks from './store/auth/thunk';
import GeneralThunks from './store/general/thunk';
import { navReplace } from './store/nav/actions';
import './style/app.scss';
import materialTheme from './style/materialTheme';
import { VERSION } from './version';
import DivDeptDialog from './modules/component/dialog/DivDeptDialog';
import { OptionType } from './@types/model/optionType';
import DataActions from './store/data/actions';

export const AppContext = createContext<IAppContext | null>(null);

const App = () : React.ReactElement => {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const selectedDivisions = localStorageService.getUserSelectedDivisionsLocalStorage();
    const selectedDepartments = localStorageService.getUserSelectedDepartmentsLocalStorage();
    
    const isLoggingIn = useAppSelector<boolean>(x => x.auth.isLoggingIn);
    const isLoggingOut = useAppSelector<boolean>(x => x.auth.isLoggingOut);
    const session = useAppSelector<null | IUserToken>(x => x.auth.session);
    const showRecoveryScreen = location.pathname.includes('/reset-password');

    const [isDivDeptOpen, setDivDeptOpen] = useState<boolean>(false);

    /*================================================================================================================
     *                                                  Effects
     * ==============================================================================================================*/

    const ReactQueryDevtoolsProduction = React.lazy(() =>
        import('@tanstack/react-query-devtools/build/lib/index.prod.js').then(
            (d) => ({
                default: d.ReactQueryDevtools,
            }),
        ),
    );

    useEffect(() => {
        loadData();
    }, []);

    const loadData = () : void => {
        dispatch(AuthActions.setLoggingIn(true));
        localStorageService.onSessionChanged((user) => {

            if (user) { 
                /* Logged In */
                initializeInterceptor(user.token, onUnauthenticated, onUnauthorized, onConnectionError);
                dispatch(AuthActions.setSession(user));
                dispatch(AuthActions.setToken(user.token));
                dispatch(AuthActions.setLoggedIn(true));
                dispatch(AuthActions.setLoggingIn(false));

            } else { 
                /* Logged Out or Not yet logged in */
                initializeInterceptor('', onUnauthenticated, onUnauthorized, onConnectionError);
                dispatch(AuthActions.setLoggedIn(false));
                dispatch(AuthActions.setSession(null));
                dispatch(AuthActions.setLoggingIn(false));
            }
        });
    };

    useEffect(() => {
        if (session?.user.divisionIds !== null && session?.user.divisionIds.length === 1) {
            const selectedDivisions : Array<IOptionType> = session.user.userDivisions
                .filter(x => x.isActive && x.division.isActive)
                .map(x => OptionType.fromDivision(x.division));
            localStorageService.setUserSelectedDivisionsLocalStorage(selectedDivisions);
            dispatch(DataActions.setSelectedUserDivisionIds(session.user.divisionIds));
        }
        
        if (session?.user.departmentIds !== null && session?.user.departmentIds.length === 1) {
            const selectedDepartments = session.user.userDepartments
                .filter(x => x.isActive && x.department.isActive)
                .map(x => OptionType.fromDepartment(x.department));
            localStorageService.setUserSelectedDepartmentsLocalStorage(selectedDepartments);
            dispatch(DataActions.setSelectedUserDepartmentIds(session.user.departmentIds));
        }
    }, [session]);

    useEffect(() => {
        if (!session) return;

        if (((selectedDivisions.length <= 0) || (selectedDepartments.length <= 0))
            && ((selectedDivisions.length <= 0) || (selectedDepartments.length <= 0)))
        {
            setDivDeptOpen(true);
        }
    }, [session, selectedDivisions, selectedDepartments]);

    /*================================================================================================================
     *                                                  Async Methods
     * ==============================================================================================================*/

    const manualLogIn = async (username : string, password : string) : Promise<void> => {
        const logIn : LogInModel = {
            username,
            password,
        };
        dispatch(AuthThunks.manualLogIn({ logIn }));
    };

    const manualSignUp = async (employeeNumber : string, password : string) : Promise<void> => {
        const googleSignUp : SignUpModel = {
            employeeNumber,
            password, 
        };

        dispatch(AuthThunks.signUp({ googleSignUp }));
    };

    const requestForgottenPassword = async (emailOrEmployeeNumber : string) : Promise<void> => {
        await dispatch(AuthThunks.requestForgottenPassword({ emailOrEmployeeNumber: emailOrEmployeeNumber }));
    };

    const onGoogleLogInSuccess = async (response : IGoogleLoginSuccess) : Promise<void> => {
        const googleLogIn : IGoogleLogInModel = {
            code: response.code,
        };
        await dispatch(AuthThunks.googleLogIn({googleLogIn: googleLogIn}));

        navReplace('/home');
    };

    /*================================================================================================================
     *                                                  Handler Methods
     * ==============================================================================================================*/

    const onUnauthenticated = (error : any) : void => {
        initializeInterceptor('', onUnauthenticated, onUnauthorized, onConnectionError);
        dispatch(GeneralThunks.showErrorSnackbar({
            defaultMessage: 'Unauthenticated.',
            ex: error,
        }));

        dispatch(AuthThunks.logout());
    };

    const onUnauthorized = (error : any) : void => {
        dispatch(GeneralThunks.showErrorSnackbar({
            defaultMessage: 'Insufficient rights.',
            ex: error,
        }));
    };

    const onConnectionError = () : void => {
        dispatch(GeneralThunks.showErrorSnackbar({
            defaultMessage: 'Connection error.',
        }));
    };

    const closeDivDept = () : void => setDivDeptOpen(false);

    const onGoogleLogInFailure = (response : IGoogleLoginError) : void => {
        dispatch(GeneralThunks.showErrorSnackbar({
            defaultMessage: response.error ?? 'Login Error',
            ex: response,
        }));
        dispatch(AuthActions.setLoggingIn(false));
    };

    /*================================================================================================================
     *                                                    Form Values
     * ==============================================================================================================*/

    const getInitialPasswordFormValues = useMemo<IPasswordRecoveryFormValues>(() => {
        return PasswordRecoveryModelHelper.createFormValues();
    }, []);

    /*================================================================================================================
     *                                                  Render Methods
     * ==============================================================================================================*/
    
    const renderApp = useMemo<React.ReactElement>(() => {
        if (isLoggingIn || isLoggingOut) {
            return <Loader/>;
        }
        
        if (session) {
            return (
                <NavFrame />
            );
        }

        return showRecoveryScreen 
            ? <PasswordRecovery initialFormValues={getInitialPasswordFormValues}/> 
            : <Login
                env={EnvHelper.getEnvName()}
                version={VERSION.version}
                isLoading={isLoggingIn}
                isLoggingIn={isLoggingIn}
                manualLogIn={manualLogIn}
                enableSignUp
                manualSignUp={manualSignUp}
                requestForgottenPassword={requestForgottenPassword}
                onGoogleSignInSuccess={onGoogleLogInSuccess}
                onGoogleSignInFailure={onGoogleLogInFailure}
            />;

    }, [isLoggingIn, isLoggingOut, session, showRecoveryScreen]);
    
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: { 
                staleTime: QUERY_DATA_STALE_TIME,
                retry: 3,
                retryDelay: 15000,
            },
            mutations: {}
        }
    });

    return (
        <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={materialTheme}>
                { renderApp }
                <SnackbarNotifier />
                { 
                    !!session &&
                    <DivDeptDialog isOpen={isDivDeptOpen} onClose={closeDivDept}/>
                }
            </ThemeProvider>
            <ReactQueryDevtools initialIsOpen={false} />
            <React.Suspense fallback={null}>
                {
                    EnvHelper.isDevelopmentQa() &&
                    <ReactQueryDevtoolsProduction />
                }  
            </React.Suspense>
        </QueryClientProvider>
    );
};

export default App;