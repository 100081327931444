import { openDb, UpgradeDB } from 'idb';
import { IFileQueue } from '../@types/model/fileUpload/fileUpload';
import { useAppDispatch } from '../@types/redux';
import GeneralThunks from '../store/general/thunk';
import { IOptionType } from '@zz2/zz2-ui';
import { IUserToken } from '../@types/model/auth/userToken/userToken';

const SESSION_NAME = 'zz2-finman-session';
const SESSION_KEY = 'zz2-finman-session-token';

const HOME_URL_KEY = 'home-url';
const FILE_NAME = 'finman-files';

const SELECTED_USER_DIVISIONS_KEY = 'finman-selected-user-divisions';
const SELECTED_USER_DEPARTMENTS_KEY = 'finman-selected-user-departments';

const SELECTED_DATE_FORMAT = 'finman-selected-user-date-format';

let sessionCallback : (userToken : IUserToken | null) => void;

export async function getLocalStorageSession() : Promise<IUserToken | null> {
    let session : IUserToken | null = null;
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        session = await getSessionIndexedDB();
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    } else if (self.localStorage) {
        session = getSessionLocalStorage();
    }

    if (session) {
        return session;
    } else {
        return null;
    }
}

export async function setLocalStorageSession(userToken : IUserToken | null) : Promise<void> {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        await setSessionIndexedDB(userToken);
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    } else if (self.localStorage) {
        setSessionLocalStorage(userToken);
    }

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (sessionCallback) {
        sessionCallback(userToken);
    }
}

function setSessionLocalStorage(userToken : IUserToken | null) : void {
    if (userToken) {
        localStorage.setItem(SESSION_KEY, JSON.stringify(userToken));
    } else {
        localStorage.removeItem(SESSION_KEY);
    }
}

function getSessionLocalStorage() : IUserToken | null {
    const session = localStorage.getItem(SESSION_KEY);

    if (session) {
        return JSON.parse(session);
    } else {
        return null;
    }
}

/**
 * Creates all object stores up to the current DB version. i.e. for version 2, this function will execute for versions
 * 0, 1 and 2.
 * @param db
 */
function upgradeDb(db : UpgradeDB) : void {
    switch (db.oldVersion) {
        case 0:
            if (!db.objectStoreNames.contains(SESSION_NAME)) {
                db.createObjectStore<IUserToken, string>(SESSION_NAME);
            }
        case 1:
            if (!db.objectStoreNames.contains(FILE_NAME)) {
                db.createObjectStore<IFileQueue, string>(FILE_NAME);
            }
    }
}

/**
 * Sets the auth session. If no session is specified, deletes the existing entry.
 * @param userToken The session.
 */
async function setSessionIndexedDB(userToken : IUserToken | null) : Promise<void> {
    const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

    const tx = db.transaction(SESSION_NAME, 'readwrite');

    const store = tx.objectStore(SESSION_NAME);

    await store.delete(SESSION_KEY);
    if (userToken) {
        await store.add(userToken, SESSION_KEY);
    }
    await tx.complete;
}

/**
 * Opens the DB and retrieves the current auth session.
 */
async function getSessionIndexedDB() : Promise<IUserToken> {
    const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

    const tx = db.transaction(SESSION_NAME, 'readonly');

    const result = tx.objectStore<IUserToken>(SESSION_NAME).get(SESSION_KEY);

    await tx.complete;

    return result;
}

/**
 * Specifies the callback that will be fired whenever the auth session undergoes a change.
 * @param callback
 */
export async function onSessionChanged(callback : (userToken : IUserToken | null) => void) : Promise<void> {
    sessionCallback = callback;
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        indexedDBSessionChange();
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    } else if (self.localStorage) {
        const session = getSessionLocalStorage();
        sessionCallback(session);
    }
}

/**
 * Retrieves auth session, and once done fires the session callback.
 */
function indexedDBSessionChange() : void {
    getSessionIndexedDB().then((res) => {
        sessionCallback(res);
    }, () => {
        sessionCallback(null);
    });
}

/**
 *  Stores Home url
 */
export function setHomeUrlStorage(url : string) : void {
    localStorage.setItem(HOME_URL_KEY, url);
}

/**
 *  Retrieves Home url
 */
export function getHomeUrlStorage() : string {
    const url = localStorage.getItem(HOME_URL_KEY);

    if (url) return url;

    return '/';
}

/**
 * Stores master data last sync date
 */
export function setUserSelectedDateFormatLocalStorage(selectedFormat : string | null) : void {
    if (selectedFormat) {
        localStorage.setItem(SELECTED_DATE_FORMAT, JSON.stringify(selectedFormat));
    } else {
        localStorage.removeItem(SELECTED_DATE_FORMAT);
    }
}

/**
 * Retrieves master data last sync date
 */
export function getUserSelectedDateFormatLocalStorage() : string | null {
    const selectedFormat = localStorage.getItem(SELECTED_DATE_FORMAT);

    if (selectedFormat) return JSON.parse(selectedFormat);

    return null;
}

///////////////////

/**
 * Stores User Selected Division Ids
 */
export function setUserSelectedDivisionsLocalStorage(divisionIds ?: Array<IOptionType>) : void {
    if (divisionIds) {
        localStorage.setItem(SELECTED_USER_DIVISIONS_KEY, JSON.stringify(divisionIds));
    } else {
        localStorage.removeItem(SELECTED_USER_DIVISIONS_KEY);
    }
}

/**
 * Retrieves User Selected Division Ids
 */
export function getUserSelectedDivisionsLocalStorage() : Array<IOptionType> {
    const divisionIds = localStorage.getItem(SELECTED_USER_DIVISIONS_KEY);

    if (divisionIds) return JSON.parse(divisionIds);

    return [];
}

/**
 * Stores User Selected Department Ids
 */
export function setUserSelectedDepartmentsLocalStorage(departmentIds ?: Array<IOptionType>) : void {
    if (departmentIds) {
        localStorage.setItem(SELECTED_USER_DEPARTMENTS_KEY, JSON.stringify(departmentIds));
    } else {
        localStorage.removeItem(SELECTED_USER_DEPARTMENTS_KEY);
    }
}

/**
 * Retrieves User Selected Department Ids
 */
export function getUserSelectedDepartmentsLocalStorage() : Array<IOptionType> {
    const departmentIds = localStorage.getItem(SELECTED_USER_DEPARTMENTS_KEY);

    if (departmentIds) return JSON.parse(departmentIds);

    return [];
}

/********************************************************************************/
/* Start of Files */

export async function setQueueFile(file : IFileQueue) : Promise<void> {
    const dispatch = useAppDispatch();
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        setQueueFileIndexedDB(file);
    } else {
        dispatch(GeneralThunks.showWarningSnackbar('Files will not persist offline.'));
    }
}

async function setQueueFileIndexedDB(file : IFileQueue) : Promise<void> {
    const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);
    const tx = db.transaction(FILE_NAME, 'readwrite');
    const store = tx.objectStore(FILE_NAME);

    await store.delete(file.guid);
    await store.add(file, file.guid);

    await tx.complete;
}

export async function deleteQueueFile(guid : string) : Promise<void> {
    const dispatch = useAppDispatch();
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        deleteQueueFileIndexedDB(guid);
    } else {
        dispatch(GeneralThunks.showWarningSnackbar('Files will not persist offline.'));
    }
}

async function deleteQueueFileIndexedDB(guid : string) : Promise<void> {
    const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

    const tx = db.transaction(FILE_NAME, 'readwrite');
    const store = tx.objectStore(FILE_NAME);

    await store.delete(guid);

    await tx.complete;
}

/**
 * Gets all queue files not sent/compeleted processing. Also removes all files that were processed.
 *
 * @export
 */
export async function getNotCompletedQueueFiles() : Promise<Array<IFileQueue>> {
    const dispatch = useAppDispatch();
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (self.indexedDB) {
        clearProcessedQueueFilesIndexedDB();
        return getQueueFilesIndexedDB();
    } else {
        dispatch(GeneralThunks.showWarningSnackbar('Files will not persist offline.'));
    }

    return [];
}

async function getQueueFilesIndexedDB() : Promise<Array<IFileQueue>> {
    const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

    const tx = db.transaction(FILE_NAME, 'readonly');

    const files = await tx.objectStore<IFileQueue>(FILE_NAME).getAll();

    await tx.complete;

    const result = files.filter(n => !n.completed);

    return result;
}

async function clearProcessedQueueFilesIndexedDB() : Promise<void> {
    const db = await openDb(INDEXEDDBNAME, Number(INDEXEDDBVERSION), upgradeDb);

    const tx = db.transaction(FILE_NAME, 'readwrite');

    const files = await tx.objectStore<IFileQueue>(FILE_NAME).getAll();

    for (const file of files) {
        if (file.completed) {
            await tx.objectStore<IFileQueue>(FILE_NAME).delete(file.guid);
        }
    }

    await tx.complete;
}

/* End of Files */
/********************************************************************************/
