
class EnvHelper {
    public static isDevelopmentQa = () : boolean => {
        return ENV_NAME === 'qa' || ENV_NAME === 'dev';
    }

    public static getEnvName = () : string => {
        if (ENV_NAME === 'production') {
            return 'FINMAN';
        } else if (ENV_NAME === 'qa') {
            return 'FINMAN QA';
        } else if (ENV_NAME === 'dev') {
            return 'FINMAN DEV';
        } else {
            return 'FINMAN ENV NOT FOUND';
        }
    };
}

export default EnvHelper;