import { IBaseModel } from '../base';

export interface ICapitalTotalValues {
    totalBudget ?: number;
    totalTrigger ?: number;
    totalTransferIn ?: number;
    totalTransferOut ?: number;
    totalSave ?: number;
    totalAdditional ?: number;
    totalMovement ?: number;
    totalAvailable ?: number;
}

export interface ICapitalTotalsDelta {
    totalTriggerChange : number;
    totalBudgetedChange : number;
    totalTransferInChange : number;
    totalTransferOutChange : number;
    totalSaveChange : number;
    totalAdditionalChange : number;
    totalAdjustedChange : number;
    totalMovementChange : number;
}

export interface ICapitalLookup {
    id : number;
    departmentId : number;
    code : string;
    name : string;
    description : string;
    motivation : string;
}

export enum CapitalPlanStatus {
    Rejected = 0,
    Draft = 1,
    Requested = 2,
    Approved = 3,
    Closed = 4,
}

export enum CapitalTransactionStatus {
    Rejected = 0,
    Draft = 1,
    Requested = 2,
    AwaitingPlanApproval = 3,
    Approved = 4,
    Closed = 5,
}

export enum CapitalTransactionPurpose {
    Budgeted = 0,
    Additional = 1,
    Saved = 2,
    Transferred = 3,
    Adjusted = 4,
    Triggered = 5,
}

export interface ICapitalGroup extends IBaseModel {
    name : string;
    departmentId : number;
    parentCapitalGroupId : number;
    parentCapitalGroup : null | ICapitalGroup;
    childCapitalGroups : null | Array<ICapitalGroup>;
}

export interface ICapitalGroupUpsert {
    id : number;
    departmentId : number;
    parentCapitalGroupId : null | number;
    name : string;
}
