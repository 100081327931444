import { useQueryClient } from '@tanstack/react-query';
import { ArrayHelper } from '@zz2/zz2-ui';
import { useAppDispatch, useAppSelector } from '../../@types/redux';
import GeneralThunks from '../../store/general/thunk';
import lodash from 'lodash';
import { IUser } from '../../@types/model/auth/user/user';
import { IUserToken } from '../../@types/model/auth/userToken/userToken';
import AuthActions from '../../store/auth/actions';
import * as localStorageService from '../../service/localStorageService';

export const queryErrorHandler = (errorMessage : string) => {
    const dispatch = useAppDispatch();

    const handleError = (error : Error) => {
        dispatch(GeneralThunks.showErrorSnackbar({
            defaultMessage: errorMessage,
            ex: error,
        }));
    };
    
    return handleError;
};

export const querySuccessHandler = <T extends { id : number }>(getKey : string, successMessage : string) => {
    const dispatch = useAppDispatch();
    const queryClient = useQueryClient();

    const handleOnSuccess = (result : T | Array<T>) => {
        const isArrayType = Array.isArray(result);
        
        queryClient.setQueriesData([getKey], (cachedData ?: Array<T>) => {
            if (!cachedData) return [result];

            if (isArrayType) {
                return ArrayHelper.upsertElements(cachedData, [...result], (a : T, b : T) => a.id == b.id);
            } else {
                return ArrayHelper.upsertElement(cachedData, result, (x => x.id === result.id));
            }
        });

        dispatch(GeneralThunks.showSuccessSnackbar(successMessage));
    };

    return handleOnSuccess;
};

export const queryDeleteSuccessHandler = <T extends { id : number; isActive : boolean }>(deleteKey : string, getKey : string, successMessage : string) => {
    const dispatch = useAppDispatch();
    const queryClient = useQueryClient();

    const handleOnSuccess = (data : void, deletedId : number) => {
        queryClient.setQueriesData([getKey], (cachedData ?: Array<T>) => {
            if (!cachedData) return [cachedData];

            const deletedData = lodash.cloneDeep(cachedData.find(x => x.id === deletedId));

            if (deletedData) {
                deletedData.isActive = false;
                return ArrayHelper.upsertElement(cachedData, deletedData, (x => x.id === deletedId));
            }
            
            return cachedData;
        });

        queryClient.invalidateQueries([deleteKey]);

        dispatch(GeneralThunks.showSuccessSnackbar(successMessage));
    };

    return handleOnSuccess;
};

export const queryNoReturnContentSuccessHandler = (successMessage : string) => {
    const dispatch = useAppDispatch();
    
    const handleOnSuccess = () => {
        dispatch(GeneralThunks.showSuccessSnackbar(successMessage));
    };

    return handleOnSuccess;
};

export const queryUpdateSessionSuccessHandler = (successMessage : string) => {
    const dispatch = useAppDispatch();
    const session = useAppSelector<IUserToken | null>(x => x.auth.session);

    const handleOnSuccess = async (data : IUser) => {
        if (!session) return;

        const updatedSession = {
            ...session,
            user: data,
        };

        dispatch(AuthActions.setSession(updatedSession));
        await localStorageService.setLocalStorageSession(updatedSession);
        
        dispatch(GeneralThunks.showSuccessSnackbar(successMessage));
    };

    return handleOnSuccess;
};
