import { IBaseRight } from '@zz2/zz2-ui';
import { IUser } from './auth/user/user';
import { IAssetGroup } from './masterData/assetGroup/assetGroup';
import { ICurrency } from './masterData/currency/currency';
import { IDepartment } from './masterData/department/department';
import { IGlAccount } from './masterData/glAccount/glAccount';
import { IGlAccountType } from './masterData/glAccountType/glAccountType';
import { VatType } from './masterData/masterData';
import { IUnitOfMeasure } from './masterData/unitOfMeasure/unitOfMeasure';
import { ISite } from './masterData/site/site';
import { IStock } from './masterData/stock/stock';
import { IStockSearch } from './masterData/stock/stockSearch';
import { IStore } from './masterData/store/store';
import { IVendor } from './masterData/vendor/vendor';
import { IValueAddedTax } from './masterData/valueAddedTax/valueAddedTax';
import { IDivision } from './masterData/division/division';
import { IEmployee } from './masterData/employee/employee';
import { ISubdivision } from './masterData/subdivision/subdivision';
import { IRegion } from './masterData/region/region';
import { ICostCentre } from './masterData/costCentre/costCentre';
import { IGlCode, IGlCodeSummary } from './masterData/glCode/glCode';
import { ICompanyReference } from './masterData/companyReference/companyReference';

export interface IOptionType<T = any> {
    label : string;
    value : number;
    resource ? : T;
}

export class OptionType {

    public static fromValueAndLabel = (value : number, label : string) : IOptionType => {
        return {
            value,
            label,
        };
    }

    public static modelToOption = <T extends {[key : string] : any}>(model : T, labelField : Array<string> = ['name'], valueField = 'id') : IOptionType => {
        return {
            value: model[valueField],
            label: labelField.map(x => model[x]).join(' - '),
        };
    }

    public static fromAssetGroup = (assetGroup : IAssetGroup) : IOptionType => {
        return {
            label: assetGroup.description,
            value: assetGroup.id,
        };
    }

    public static fromDivision = (division : IDivision) : IOptionType => {
        return {
            label: division.name,
            value: division.id,
        };
    }

    public static fromSubdivision = (subdivision : ISubdivision) : IOptionType => {
        return {
            label: subdivision.name,
            value: subdivision.id,
        };
    }

    public static fromCurrency = (currency : ICurrency) : IOptionType => {
        return {
            label: `${currency.code} (${currency.symbol})`,
            value: currency.id,
        };
    }

    public static fromDepartment = (department : IDepartment) : IOptionType => {
        return {
            label: department.name,
            value: department.id,
        };
    }

    public static fromCostCentre = (costCentre : ICostCentre) : IOptionType => {
        return {
            label: `${costCentre.name} ${costCentre.description}`,
            value: costCentre.id,
        };
    }

    public static fromGlCode = (glCode : IGlCode) : IOptionType<IGlCode> => {
        return {
            label: `${glCode.segment1 || glCode.code} | ${glCode.segment1Description}`,
            value: glCode.id,
        };
    }

    public static fromGlCodeAlternate = (glCode : IGlCode) : IOptionType<IGlCode> => {
        return {
            label: `${glCode.code} (${glCode.description})`,
            value: glCode.id,
        };
    }

    public static fromGlCodeSummary = (glCodeSummary : IGlCodeSummary) : IOptionType<IGlCodeSummary> => {
        return {
            label: `${glCodeSummary.code} - ${glCodeSummary.description}`,
            value: glCodeSummary.id,
        };
    }

    public static fromGlAccount = (glAccount : IGlAccount) : IOptionType<IGlAccount> => {
        return {
            label: `${glAccount.code} | ${glAccount.description}`,
            value: glAccount.id,
        };
    }

    public static fromGlAccountType = (glAccountType : IGlAccountType) : IOptionType<IGlAccountType> => {
        return {
            label: glAccountType.description,
            value: glAccountType.id,
        };
    }

    public static fromUnitOfMeasure = (unitOfMeasure : IUnitOfMeasure) : IOptionType<IUnitOfMeasure> => {
        return {
            label: `${unitOfMeasure.name} (${unitOfMeasure.symbol})`,
            value: unitOfMeasure.id,
        };
    }

    public static fromRequisitionStatus = (reqStatuses : any) : IOptionType<any> => {
        return {
            label: reqStatuses.label,
            value: reqStatuses.id,
        };
    }

    public static fromSite = (site : ISite) : IOptionType<ISite> => {
        return {
            label: `${site.code} (${site.name})`,
            value: site.id,
        };
    }

    public static fromSiteNameOnly = (site : ISite) : IOptionType<ISite> => {
        return {
            label: `${site.name}`,
            value: site.id,
        };
    }

    public static fromStock = (stock : IStock) : IOptionType<IStock> => {
        return {
            label: `${stock.code} (${stock.description})`,
            value: stock.id,
        };
    }

    public static fromStockSearch = (stock : IStockSearch) : IOptionType<IStock> => {
        return {
            label: `${stock.stockCode} (${stock.stockDescription})`,
            value: stock.id,
        };
    }

    public static fromStore = (store : IStore) : IOptionType<IStore> => {
        return {
            label: `${store.code} (${store.name})`,
            value: store.id,
        };
    }

    public static fromStoreNameOnly = (store : IStore) : IOptionType<IStore> => {
        return {
            label: `${store.code} (${store.name})`,
            value: store.id,
        };
    }  

    public static fromVendor = (vendor : IVendor) : IOptionType => {
        return {
            label: `${vendor.code} | ${vendor.name}`,
            value: vendor.id,
        };
    }
    
    public static fromVendorNameOnly = (vendor : IVendor) : IOptionType => {
        return {
            label: vendor.name,
            value: vendor.id,
        };
    }

    public static fromRegion = (region : IRegion) : IOptionType => {
        return {
            label: region.description,
            value: region.id,
        };
    }

    public static fromCompanyReference = (companyReference : ICompanyReference) : IOptionType => {
        return {
            label: companyReference.description,
            value: companyReference.id,
        };
    }

    public static fromVatType = (vatType : VatType) : IOptionType => {
        return {
            label: VatType[vatType],
            value: vatType,
        };
    }

    public static fromValueAddedTax = (valueAddedTax : IValueAddedTax) : IOptionType => {
        return {
            label: (valueAddedTax.value * 100).toString(),
            value: valueAddedTax.id,
        };
    };

    /*================================================================================================================
     *                                                  Auth
     * ==============================================================================================================*/

    public static fromRight = (right : IBaseRight) : IOptionType => {
        return {
            label: `(${right.code}) ${right.name}`,
            value: right.id,
        };
    };

    public static fromUser = (user : IUser) : IOptionType => {
        return {
            label: user.name,
            value: user.id,
        };
    };

    public static fromEmployee = (employee : IEmployee) : IOptionType => {
        return {
            label: `${employee.number} - ${employee.name}`,
            value: employee.id
        };
    };
}