import { createAsyncThunk } from '@reduxjs/toolkit';
import { IGoogleLogInModel } from '../../@types/model/auth/logIn/GoogleLogInModel';
import { LogInModel } from '../../@types/model/auth/logIn/LogInModel';
import { SignUpModel } from '../../@types/model/auth/logIn/SignUpModel';
import { ThunkApi, createAppAsyncThunk } from '../../@types/redux';
import AuthHttpService from '../../service/http/right/authHttpService';
import UserHttpService from '../../service/http/right/userHttpService';
import * as localStorageService from '../../service/localStorageService';
import GeneralThunks from '../general/thunk';
import { navPath, navReplace } from '../nav/actions';
import AuthActions from './actions';

export default class AuthThunks {
    /**
     * Performs sign up request with API then sets the interceptor (using auth token) and auth state accordingly. Kicks off
     * the setup for local storage service to store the session and, in turn, firing its callback (that is set during app
     * init).
     *
     * @param employeeNumber
     * @param password
     */
    public static signUp = createAppAsyncThunk(
        'AUTH_SIGN_UP',
        async (params : {
            googleSignUp : SignUpModel;
        }, ThunkApi) => {
            try {
                ThunkApi.dispatch(AuthActions.setLoggingIn(true));

                const res = await AuthHttpService.signUp(params.googleSignUp);
                await localStorageService.setLocalStorageSession(res);
                    
            } catch (e) {
                ThunkApi.dispatch(AuthActions.setLoggingIn(false));
                ThunkApi.dispatch(GeneralThunks.showErrorSnackbar({ defaultMessage: 'An error occurred while signing up.', ex: e }));
            } finally {
                ThunkApi.dispatch(AuthActions.setLoggingIn(false));
            }
        }
    );

    /**
     * Performs google log in request with API then sets the interceptor (using auth token) and auth state accordingly. Kicks off
     * the setup for local storage service to store the session and, in turn, firing its callback (that is set during app
     * init).
     *
     * @param idToken
     */
    public static googleLogIn = createAppAsyncThunk(
        'AUTH_GOOGLE_LOG_IN',
        async (params : {
            googleLogIn : IGoogleLogInModel;
        }, ThunkApi) => {
            try {
                ThunkApi.dispatch(AuthActions.setLoggingIn(true));

                const res = await AuthHttpService.googleLogIn(params.googleLogIn);

                await localStorageService.setLocalStorageSession(res);
                
                ThunkApi.dispatch(AuthActions.setSession(res));
                
                navReplace('/home');
                ThunkApi.dispatch(GeneralThunks.showSuccessSnackbar('Success'));
            } catch (e) {
                ThunkApi.dispatch(GeneralThunks.showErrorSnackbar({ defaultMessage: 'An error occurred while logging in.', ex: e }));
            } finally {
                ThunkApi.dispatch(AuthActions.setLoggingIn(false));
            }
        }
    )

    /**
     * Performs manual log in request with API then sets the interceptor (using auth token) and auth state accordingly. Kicks off
     * the setup for local storage service to store the session and, in turn, firing its callback (that is set during app
     * init).
     *
     * @param username
     * @param password
     */
    public static manualLogIn = createAppAsyncThunk(
        'AUTH_MANUAL_LOG_IN',
        async (params : {
            logIn : LogInModel;
        }, ThunkApi) => {
            try {
                ThunkApi.dispatch(AuthActions.setLoggingIn(true));
    
                const res = await AuthHttpService.logIn(params.logIn);

                await localStorageService.setLocalStorageSession(res);

                ThunkApi.dispatch(AuthActions.setSession(res));

                navReplace('/home');
                ThunkApi.dispatch(GeneralThunks.showSuccessSnackbar('Success'));
            } catch (e) {
                ThunkApi.dispatch(GeneralThunks.showErrorSnackbar({ defaultMessage: 'An error occurred while logging in.', ex: e }));
            } finally {
                ThunkApi.dispatch(AuthActions.setLoggingIn(false));

            }
        }
    )

    /**
     * Performs request for password reset email
     *
     * @param emailOrEmployeeNumber
     */
    public static requestForgottenPassword = createAsyncThunk<
    boolean | null,
    {
        emailOrEmployeeNumber : string;
    },
    ThunkApi>(
        'REQUEST_FORGOTTEN_PASSWORD',
        async (params, thunkApi) => {
            try {
                thunkApi.dispatch(AuthActions.setLoggingIn(true));

                const res = await UserHttpService.requestForgottenPassword(params.emailOrEmployeeNumber);
                if (res) {
                    thunkApi.dispatch(GeneralThunks.showSuccessSnackbar('Password recovery submitted, please check your email.'));
                    return res;
                } else {
                    thunkApi.dispatch(GeneralThunks.showErrorSnackbar({defaultMessage: 'Password recovery could not be submitted.'}));
                    return null;
                }
            } catch (e) {
                thunkApi.dispatch(GeneralThunks.showErrorSnackbar({ defaultMessage: 'An error occurred while recovering password.', ex: e }));
                return null;
            } finally {
                thunkApi.dispatch(AuthActions.setLoggingIn(false));
            }
        },
    );

    /**
     * Performs log out request with API then sets the auth state accordingly.
     * 
     * @param userId
     */
    public static logout = createAppAsyncThunk(
        'AUTH_LOG_OUT',
        async (params, ThunkApi) => {
            try {
                ThunkApi.dispatch(AuthActions.setLoggingOut(true));

                await AuthHttpService.logout();
    
                await localStorageService.setLocalStorageSession(null);
    
                ThunkApi.dispatch(AuthActions.logout());
                navPath('/logIn');

                ThunkApi.dispatch(GeneralThunks.showSuccessSnackbar('Logout Success.'));
            } catch (e) {
                ThunkApi.dispatch(GeneralThunks.showErrorSnackbar({ defaultMessage: 'An error occurred while logging out.', ex: e }));
                await localStorageService.setLocalStorageSession(null);
                ThunkApi.dispatch(AuthActions.setLoggingOut(false));

            } finally {
                ThunkApi.dispatch(AuthActions.setLoggingOut(false));
            }
        }
    )

    /**
     * Performs log out request with API for the provided user IDs then sets the auth state accordingly.
     * 
     * @param userIds
     */
    public static logoutUsers = createAppAsyncThunk(
        'AUTH_LOGOUT_USERS',
        async (params : {
            currentUserId : number;
            userIds : Array<number>;
        }, ThunkApi) => {
            try {    
                ThunkApi.dispatch(AuthActions.setLoggingOut(true));
                await UserHttpService.logoutUsers(params.userIds);
    
                if (params.userIds.find(x => x === params.currentUserId)) {
                    await localStorageService.setLocalStorageSession(null);
                    
                    ThunkApi.dispatch(AuthActions.logout());
                    navPath('/logIn'); 
                }

            } catch (e) {
                ThunkApi.dispatch(GeneralThunks.showErrorSnackbar({ defaultMessage: 'An error occurred while logging out users.', ex: e }));
                await localStorageService.setLocalStorageSession(null);
            } finally {
                ThunkApi.dispatch(AuthActions.setLoggingOut(false));
            }
        }
    )
}