import { IBaseModel } from '../base';
import moment from 'moment';
import { IGlCode } from '../masterData/glCode/glCode';

export enum RequisitionStatus {
    Cancelled,
    Draft,
    Submitted,
    Ordered,
    PartiallyReceived,
    Received,
    Closed,
}

export enum RequisitionLineStatus {
    Cancelled,
    Draft,
    Submitted,
    Ordered,
    PartiallyReceived,
    Received,
    Closed,
}

export enum RequisitionType {
    Procurement,
    Stores,
    Capital,
}

export interface IRequisition extends IBaseModel {
    divisionId : number;
    requisitionNumber : string;
    orderNumber : string | null;
    defaultAllocationNumber : string;
    glCodeId : number | null;
    glCode : IGlCode | null;
    referenceDescription : string;
    referenceNumber : string | null;
    notifyName : string;
    notifyEmployeeNumber : string;
    notifyDescription : string;
    status : RequisitionStatus;
    requestedOn : moment.Moment | null;
    requestedByName : string;
    requestedByEmployeeNo : string;
    approvedOn : moment.Moment | null;
    approvedByName : string | null;
    approvedByEmployeeNo : string | null;
}
