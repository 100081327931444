import axios, { CancelTokenStatic, Canceler } from 'axios';
import { IRightUpsert } from '../../../@types/model/auth/right/rightUpsert';
import { IBaseRight } from '@zz2/zz2-ui';

export default class RightHttpService {
    private static readonly CancelToken : CancelTokenStatic = axios.CancelToken;
    /**
     * Allows you to cancel list request
     */
    public static cancelGetList ?: Canceler;

    public static getList = async () : Promise<Array<IBaseRight>> => {
        const result = await axios.get(`${API_URL}/v1/Right/GetList`);
        return result.data;
    }

    public static upsert = async (rightUpsert : IRightUpsert) : Promise<IBaseRight> => {
        const result = await axios.post(`${API_URL}/v1/Right/Upsert`, rightUpsert);
        return result.data;
    }

    public static delete = async (rightId : number) : Promise<void> => {
        const result = await axios.delete(`${API_URL}/v1/Right/Delete`, {
            params: { rightId },
        });
        return result.data;
    }
}